import React from 'react'
import { Container, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    pagetitle:{
     color: '#231f20',
     fontFamily:'Poppins',
     paddingTop:'30px',
     fontSize: '42px',
     [theme.breakpoints.down("sm")]: {
        fontSize: '30px',
     }
   },
   pagesubtitle:{
    fontFamily: 'Poppins',
    margin: '15px',
    fontSize: '20px',
    [theme.breakpoints.down("sm")]: {
        fontSize: '14px',
     }
},
pagecontent: {
    fontFamily: 'Poppins',
    margin: '10px',
    fontSize: '18px'
},
img: {
    height: '250px', 
    padding:'10px',
    marginBottom:'20px',
    border: '3px solid #272e74'    
},
link: {   
    fontFamily: 'Poppins',
    margin: '25px',
    fontSize: '32px',
    float: 'right',
    '&:hover': {
        transform:'scale(1.1)',
        transition: 'transform .3s ease',
       }
},

 }));
 
export default function G70chain() {
    const classes = useStyles();
    return (
        <div>
     <Container maxWidth="md" component="main" style={{minHeight:'50vh'}}>
        <Typography  align="center" className={classes.pagetitle} >
             G4 Stainless
        </Typography>  

          <Typography className={classes.pagesubtitle}>
          Lynx grade 70 chain is a higher strength welded alloy chain made to NACM standards that is proof load tested and designed for use in the transportation industry to bind and secure loads to the decks of trailers.  To guard against corrosion, the chain undergoes a transformative process that bonds a layer of yellow zinc chromate to the surface of each link.  Lynx G70 chain is only available in bulk in 3/8” diameter x 75’ pails.
          </Typography>
         
    </Container>
    <div style={{marginBottom: '50px'}}></div>
    
     </div>
    )
}
