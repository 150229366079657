import React from 'react'
import {  Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Sealand from './../assets/sealand.png'

const useStyles = makeStyles(theme => ({
    pagetitle:{
     color: '#231f20',
     paddingTop:'30px',
     fontSize: '42px',
     fontFamily:'Poppins',
     [theme.breakpoints.down("sm")]: {
        fontSize: '28px',
      },
   },
   pagesubtitle:{
    fontFamily:'Poppins',
    paddingBottom: '40px', 
    fontSize:'20px'
   },
   img:{
    width: '50%',
    height:'auto',
    margin:'5px' 
   },
[theme.breakpoints.down("sm")]: {
    width: '80%',
  },
  contact:{
    fontFamily:'Poppins',
    textDecoration: 'none',
    color: 'black',
    fontSize:'20px',
    margin:'10px'
},

 }));

export default function About() {
    const classes = useStyles();
    return (
        <div id="about" style={{backgroundColor: '#d6d9dd', paddingBottom: '50px'}}>    
            <Container maxWidth="lg" component="main" >
                <Typography  align="center" className={classes.pagetitle} gutterBottom>
                About Us & Contact Info
                </Typography>   
                <Typography  className={classes.pagesubtitle}>
                Lynx chain products are products of Sea‐Land Distributors, LLC and the Lynx name and logo are registered trademarks.
                To learn more about Sea‐Land Distributors, LLC go to <a href="http://sldrigging.com/" target="_blank" rel = "noopener noreferrer" style={{color:'black'}}>SLDrigging.com.</a>               
             </Typography>   
             <div align="center">
             <a href="http://sldrigging.com/" target="_blank" >
            <img src ={Sealand} className={classes.img} alt=""/></a></div>
              <Typography   align="center"  className={classes.contact}>1000 Edwards Avenue, Suite C   </Typography>
                <Typography  className={classes.contact} align="center">New Orleans, Louisiana 70123 USA  </Typography>
                                         
              <Typography  align="center" className={classes.contact}><a href='tel:504-736-9477' className={classes.contact}> T: 504-736-9477 </a>  
               <a href='mailto:sales@sldrigging.com' className={classes.contact}> E: sales@sldrigging.com  </a></Typography> 
             </Container>   
        </div>
    )
}
