import React from 'react'
import {Container, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CommonTable from './CommonTable';
import Img1 from './../assets/gal1.jpg'
import Img2 from './../assets/gal2.jpg'
import ModalImage from 'react-modal-image'


const useStyles = makeStyles(theme => ({
    pagetitle:{
     color: '#231f20',
     fontFamily:'Poppins',
     paddingTop:'30px',
     fontSize: '42px',
     [theme.breakpoints.down("sm")]: {
        fontSize: '30px',
     }
   },
   pagesubtitle:{
    paddingBottom: '40px', 
    fontFamily: 'Poppins',
    fontSize: '20px',
    [theme.breakpoints.down("sm")]: {
        fontSize: '14px',
     }
   },
   card: {
    width: '300px',
    '&:hover': {
        transform:'scale(1.1)',
        transition: 'transform .3s ease',
        border: '3px solid #807F7F',
       }
   },
 }));
 
export default function Cable() {
    const classes = useStyles();
    return (
        <div>           
            <Container maxWidth="md" component="main" className={classes.heroContent} style={{minHeight:'50vh'}}>
             <Typography  align="center"className={classes.pagetitle} gutterBottom>
             G30 GALVANIZED CHAIN
            </Typography>  
            <Typography className={classes.pagesubtitle}>
            Lynx grade 30 hot dip galvanized chain is a welded chain made to NACM standards and is commonly used in applications at home, marine, commercial and industrial applications and farms and ranches.  For maximum corrosion resistance, the chain is hot dip galvanized.  Lynx grade 30 galvanized chains are available in pails and mini-kegs.
            </Typography>
            {/* <Grid container justify="center" alignItems="center" style={{ padding: '20px'}}>
           <Grid item md={6} sm={6} xs={12} style={{textAlign:'center', padding: '5px'}}>
           <ModalImage
                      small={Img1}
                      large={Img1} hideDownload={true} hideZoom={true}
                      className={classes.card} alt=""
                     />          
           </Grid>
           <Grid item md={6} sm={6} xs={12} style={{padding: '5px'}}>
           <ModalImage
                      small={Img2}
                      large={Img2} hideDownload={true} hideZoom={true}
                      className={classes.card} alt=""
                     /> 
            </Grid>
            </Grid>     
            
            <Typography className={classes.pagesubtitle} align="center" className={classes.pagesubtitle}>
            LYNX HOT DIP GALVANIZED CHAIN <br/>3/16 inch - 3/8 inch Diameter
            </Typography> 
                <CommonTable/> */}
             </Container>
        </div>
    )
}
