import './App.css';
import React from "react";
import {  BrowserRouter as Router,  Switch,  Route} from "react-router-dom";
import Home from './components/Home';
import Privacy from './components/Privacy'
import Termsofuse from './components/Termsofuse'
import Contactform from './components/Contactform'
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from './components/Appbar'
import  Box   from '@material-ui/core/Box';
import Drawer from './components/Drawer'
import Footer from './components/Footer';
import Galvanized from './components/GalvanizedChain';
import Zinc from './components/ZincPLated';
import Binder from './components/BinderChain';
import Binder3 from './components/BinderChain30';
import G70 from './components/G70chain'

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

 function App() {
  return (
    <div>
       <ScrollToTop /> 
       <Navbar/>   
      <Box display={{ md: 'block', lg: 'none' }} ><Drawer/></Box> 
      <Box display={{ xs: 'none', sm: 'block', md: 'none' }}><br/><br/></Box>
       <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route path="/contactform" component={Contactform}></Route>
        <Route path="/privacypolicy" component={Privacy}></Route>
        <Route path="/termsofuse" component={Termsofuse}></Route>
        <Route path="/g70binderchain" component={Binder}></Route>
        <Route path="/g70chain" component={G70}></Route>
        <Route path="/g30binderchain" component={Binder3}></Route>        
        <Route path="/zincplatedchain" component={Zinc}></Route>
        <Route path="/galvanizedchain" component={Galvanized}></Route>
      </Switch>
      <Footer/>
    </div>
  );
}


export default App;
