import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Img1 from './../assets/zinc1.jpg'
import Img2 from './../assets/zinc2.jpg'
import ModalImage from 'react-modal-image'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
   theader: {
    fontWeight: 'bold',
    fontSize: '18px',
    fontFamily: 'Poppins'
},
tcell: {
 fontFamily: 'Poppins', 
}
 }));

export default function CommonTable() {
    const classes = useStyles();
    return (
        <div>
              <TableContainer component={Paper} style={{marginBottom: '15px'}} >
          <Table className={classes.table} aria-label="simple table" style={{padding: '5px'}}>
             <TableRow style={{backgroundColor:'#807F7F'}}>
                <TableCell className={classes.theader}>Size</TableCell>
                <TableCell  className={classes.theader}>Length (ft)</TableCell>
                <TableCell className={classes.theader}>Packaging</TableCell>
                <TableCell className={classes.theader}>WLL (lbs)</TableCell>
                <TableCell className={classes.theader}>Nominal Wire Size (A)</TableCell>
                <TableCell className={classes.theader}>Max. Inside Length (B)</TableCell>
                <TableCell className={classes.theader}>Min. Inside Width (C)</TableCell>
                <TableCell className={classes.theader}>Weight (lbs)</TableCell>
              </TableRow>      
             <TableRow>
                <TableCell className={classes.tcell}>3/16" </TableCell>
                <TableCell className={classes.tcell} >140</TableCell>
                <TableCell className={classes.tcell} >Mini-Keg</TableCell>
                <TableCell className={classes.tcell} >800</TableCell>
                <TableCell className={classes.tcell} >0.217</TableCell>
                <TableCell className={classes.tcell} >0.980</TableCell>
                <TableCell className={classes.tcell} >0.300</TableCell>
                <TableCell className={classes.tcell} >53</TableCell>
            </TableRow>    
            <TableRow>
                <TableCell className={classes.tcell}> 3/16"</TableCell>
                <TableCell className={classes.tcell}>	250</TableCell>
                <TableCell className={classes.tcell}>	Pail</TableCell>
                <TableCell className={classes.tcell}>	800</TableCell>
                <TableCell className={classes.tcell}>	0.217</TableCell>
                <TableCell className={classes.tcell}>	0.980</TableCell>
                <TableCell className={classes.tcell}>	0.300</TableCell>
                <TableCell className={classes.tcell}>		100</TableCell>
            </TableRow>   
            <TableRow>
                <TableCell className={classes.tcell}> 1/4"</TableCell>
                <TableCell className={classes.tcell}>	90</TableCell>
                <TableCell className={classes.tcell}>	Mini-Keg</TableCell>
                <TableCell className={classes.tcell}>	1300</TableCell>
                <TableCell className={classes.tcell}>	0.276</TableCell>
                <TableCell className={classes.tcell}>	1.240</TableCell>
                <TableCell className={classes.tcell}>	0.380</TableCell>
                <TableCell className={classes.tcell}>55</TableCell>
            </TableRow>
            <TableRow>
                <TableCell className={classes.tcell}> 1/4"</TableCell>
                <TableCell className={classes.tcell}>	141</TableCell>
                <TableCell className={classes.tcell}>	Pail</TableCell>
                <TableCell className={classes.tcell}>	1300</TableCell>
                <TableCell className={classes.tcell}>	0.276</TableCell>
                <TableCell className={classes.tcell}>	1.240</TableCell>
                <TableCell className={classes.tcell}>	0.380</TableCell>
                <TableCell className={classes.tcell}>		86.25</TableCell>
            </TableRow>
            <TableRow>
                <TableCell className={classes.tcell}> 5/16"</TableCell>
                <TableCell className={classes.tcell}>	67</TableCell>
                <TableCell className={classes.tcell}>	Mini-Keg</TableCell>
                <TableCell className={classes.tcell}>	1900</TableCell>
                <TableCell className={classes.tcell}>	0.331</TableCell>
                <TableCell className={classes.tcell}>	1.290</TableCell>
                <TableCell className={classes.tcell}>	0.440</TableCell>
                <TableCell className={classes.tcell}>	86</TableCell>
            </TableRow>
            <TableRow>
                <TableCell className={classes.tcell}> 3/8"</TableCell>
                <TableCell className={classes.tcell}>	42</TableCell>
                <TableCell className={classes.tcell}>	Mini-Keg</TableCell>
                <TableCell className={classes.tcell}>	2650</TableCell>
                <TableCell className={classes.tcell}>	0.394</TableCell>
                <TableCell className={classes.tcell}>	1.380</TableCell>
                <TableCell className={classes.tcell}>	0.550</TableCell>
                <TableCell className={classes.tcell}>	54</TableCell>
            </TableRow>
            <TableRow>
                <TableCell className={classes.tcell}> 3/16"</TableCell>
                <TableCell className={classes.tcell}>	250</TableCell>
                <TableCell className={classes.tcell}>	Pail</TableCell>
                <TableCell className={classes.tcell}>	800</TableCell>
                <TableCell className={classes.tcell}>	0.217</TableCell>
                <TableCell className={classes.tcell}>	0.980</TableCell>
                <TableCell className={classes.tcell}>	0.300</TableCell>
                <TableCell className={classes.tcell}>		100</TableCell>
            </TableRow>
            <TableRow>
                <TableCell className={classes.tcell}> 3/8"</TableCell>
                <TableCell className={classes.tcell}>	63</TableCell>
                <TableCell className={classes.tcell}>	Pail</TableCell>
                <TableCell className={classes.tcell}>	2650</TableCell>
                <TableCell className={classes.tcell}>	0.394</TableCell>
                <TableCell className={classes.tcell}>	1.380</TableCell>
                <TableCell className={classes.tcell}>	0.550</TableCell>
                <TableCell className={classes.tcell}>		54</TableCell>
            </TableRow> 
          </Table>
        </TableContainer>
        </div>
    )
}
