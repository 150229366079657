import React from 'react'
import {Grid, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Img1 from './../assets/binder1.png'
import Img2 from './../assets/binder2.png'
import ModalImage from 'react-modal-image'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
    pagetitle:{
     color: '#231f20',
     fontFamily:'Poppins',
     padding:'30px',
     fontSize: '42px',
     [theme.breakpoints.down("sm")]: {
      fontSize: '30px',
   }
   },
   pagesubtitle:{
    paddingBottom: '10px', 
    fontFamily: 'Poppins',
    fontSize: '25px',
    [theme.breakpoints.down("sm")]: {
      fontSize: '14px',
   }
   },
   pagecontent:{
    padding: '10px', 
    fontFamily: 'Poppins',
    fontSize: '14px',
    [theme.breakpoints.down("sm")]: {
      fontSize: '12px',
   }
   },
   card: {
    width: '300px',
    '&:hover': {
        transform:'scale(1.1)',
        transition: 'transform .3s ease',
        border: '3px solid #807F7F',
       }
   },
   theader: {
       fontWeight: 'bold',
       fontSize: '18px',
       fontFamily: 'Poppins',
       [theme.breakpoints.down("sm")]: {
        fontSize: '12px',
     }
   },
   tcell: {
    fontFamily: 'Poppins', 
   }
 }));
 
export default function BinderChain30() {
    const classes = useStyles();
    return (
        <div>
        <Container maxWidth="md" component="main" className={classes.heroContent} style={{minHeight:'50vh'}}>
        <Typography  align="center"className={classes.pagetitle} >
        G30 BINDER CHAIN
       </Typography>   
       <Grid container justify="center" alignItems="center" style={{border: '1px solid #e4e4e4', padding: '20px'}}>
           <Grid item md={6} sm={6} xs={12} style={{textAlign:'center', padding: '5px'}}>
           <ModalImage
                      small={Img1}
                      large={Img1} hideDownload={true} hideZoom={true}
                      className={classes.card} alt=""
                     />          
           </Grid>
           <Grid item md={6} sm={6} xs={12} style={{padding: '5px'}}>
           <Typography className={classes.pagesubtitle} align="center" >3/8" x 75' Grade 70, Yellow Chromate, Transport Chain, Pail</Typography>
        <TableContainer component={Paper} style={{marginBottom: '15px'}} >
          <Table className={classes.table} aria-label="simple table" style={{padding: '5px'}}>
            <TableRow style={{backgroundColor:'#807F7F'}}>
            <TableCell className={classes.theader}>Features</TableCell>
            </TableRow>  
            <TableBody >  
             <div style={{padding: '9px'}}>
            <Typography style={{ fontFamily:'Poppins'}}><strong>Material:</strong> Yellow Zinc Chromate Alloy Steel <br/><strong>Weight:</strong> 1.43 lbs per foot. Package Weight: 107.25 lbs <br/><strong>Working Load Limit (WLL):</strong> 6600 lbs<br/><strong>Size:</strong>3/8 inches<br/><strong>Length:</strong>75 ft</Typography>
            </div>
            </TableBody> 
            </Table>
        </TableContainer>          
           </Grid>
        </Grid>   
             

        <Typography className={classes.pagecontent}  >
        3/8" x 75' Yellow Chromate, Grade 70, Transport Chain. Pail, Imported, Working Load Limit: 6600 lbs<br/>        
         Part No.: 31400082
           <p style={{color: 'red'}}> $268.54 </p>
            <strong>Note:</strong> Meets NACM standards
           </Typography>      
        
        <Grid container justify='center' alignItems='center' spacing={2} style={{border: '1px solid #e4e4e4', padding: '20px'}} >
        <Grid item md={6} sm={6} xs={12} style={{textAlign:'center', padding: '5px'}}>
        <ModalImage
                      small={Img2}
                      large={Img2} hideDownload={true} hideZoom={true}
                      className={classes.card} alt=""
                     />         
        </Grid>
        <Grid item  xs={12} sm={6} md={6}>
        <TableContainer component={Paper} style={{marginBottom: '15px'}} >
          <Table className={classes.table} aria-label="simple table" style={{padding: '5px'}}>
             <TableRow style={{backgroundColor:'#807F7F'}}>
                <TableCell className={classes.theader}>Name</TableCell>
                <TableCell  className={classes.theader}>Value</TableCell>
              </TableRow>      
             <TableRow>
                <TableCell className={classes.tcell}>Wire Size (A): </TableCell>
                <TableCell className={classes.tcell} >0.406 inches</TableCell>
            </TableRow>    
            <TableRow>
            <TableCell className={classes.tcell}> Maximum Inside Length (B): </TableCell>
            <TableCell className={classes.tcell}>1.380 inches</TableCell>
            </TableRow>   
            <TableRow>
             <TableCell className={classes.tcell}> Minimum Inside Width (C): </TableCell>
        <TableCell className={classes.tcell}>0.550 inches</TableCell>
            </TableRow>  
          </Table>
        </TableContainer>
        </Grid>
        </Grid>
        </Container>
        <div style={{marginBottom: '60px'}}></div>
        </div>
    )
}
